<template>
	<section class="booking-success">
		<div class="text-center">
			<div class="booking-success__text">
				<h1>{{ $t("ProductBooking.Congratulations") }}</h1>
				<p>{{ $t("ProductBooking.Contact") }}</p>
			</div>
			<a href="#" @click="backToHome">{{ $t("ProductBooking.BackToHome") }}</a>
		</div>
	</section>
</template>

<script>
export default {
	name: "BookingSuccessfully",

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		backToHome() {
			window.parent.postMessage("back-home-page", "*")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.booking-success {
	@include form;
	@include font-lato;

	&__text {
		margin-bottom: 40px;
	}

	h1 {
		font-weight: bold;
		font-size: 32px;
		background: $linear-gradient-pink;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	p {
		color: $color-black-pearl;
		font-weight: 400;
	}

	a {
		font-weight: bold;
		color: $color-white;
		background: $color-blue;
		padding: 14px 32px;
		border-radius: 4px;
		font-size: 16px;
		text-decoration: none;
	}
}
</style>
